import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recomendaciones-seguridad',
  templateUrl: './recomendaciones-seguridad.component.html',
  styleUrls: ['./recomendaciones-seguridad.component.css']
})
export class RecomendacionesSeguridadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
