import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sitios-interes',
  templateUrl: './sitios-interes.component.html',
  styleUrls: ['./sitios-interes.component.css']
})
export class SitiosInteresComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
