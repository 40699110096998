import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seguridad-salud-trabajo',
  templateUrl: './seguridad-salud-trabajo.component.html',
  styleUrls: ['./seguridad-salud-trabajo.component.css']
})
export class SeguridadSaludTrabajoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
