import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-administracion-riesgos',
  templateUrl: './administracion-riesgos.component.html',
  styleUrls: ['./administracion-riesgos.component.css']
})
export class AdministracionRiesgosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
