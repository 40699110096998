import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { City } from 'src/City';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit, AfterViewInit {

  title = 'angular-gmap';

  bogota = new City(4.670071, -74.059227, "Carrera 19 B No. 83-02 Oficina 602-605", 6171411, 6171411, "bogota")
  medellin = new City(6.242186, -75.575141, "Carrera 55 No. 40A-20 Oficina 807", 5578081, 6171411, "medellin")
  cali = new City(3.419205, -76.548535, "Carrera 37A No. 7-20", 3112163483, 6171411, "cali")
  tunja = new City(5.533245, -73.362885, "Carrera 11 No. 19-90 of 221", 3102691242, 0, "tunja")
  bucaramanga = new City(7.117187, -73.111312, "Carrera 33 No. 47-34 Oficina 807", 6569735, 6171411, "bucaramanga")
  ibague = new City(4.440413, -75.239703, "Calle 20A No. 11B-15 sur", 3232895554, 0, "ibague")
  armenia = new City(4.532983, -75.672848, "Calle 20 No. 13-22 Piso 7", 3204151790, 6171411, "armenia")
  santaMarta = new City(0, -0, "", 0, 0, "santaMarta")
  barranquilla = new City(0, -0, "", 0, 0, "barranquilla")

  @ViewChild('mapContainer', { static: true }) gmap: ElementRef;
  map: google.maps.Map;
  coordinatesB = new google.maps.LatLng(this.bogota.lat, this.bogota.long);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinatesB,
    zoom: 19
  };
  image = 'https://firebasestorage.googleapis.com/v0/b/r-y-m-897c1.appspot.com/o/LogoJargu.png?alt=media&token=bad93fdc-64b8-479f-8467-44811efdcd3e';
  selected = this
  marker = new google.maps.Marker({
    position: this.coordinatesB,
    map: this.map,
    title: 'Hello World!',
    icon: this.image
  });
  phone1 = this.bogota.phone1
  phone2 = this.bogota.phone2
  address = this.bogota.address
  constructor() {

  }


  ngAfterViewInit() {
    this.mapInitializer();
  }

  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.marker.setMap(this.map);
  }
  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  evento;

  changeCity(ciudad: City, event) {
    event.preventDefault();
    if (this.evento != undefined) {
      this.evento.target.classList.remove('citySel');
    }
    else {
      var element = document.getElementById("bog");
      element.classList.remove("citySel");
    }
    this.evento = event
    this.coordinatesB = new google.maps.LatLng(ciudad.lat, ciudad.long);
    this.marker.setMap(this.map);
    this.map.setCenter({ lat: ciudad.lat, lng: ciudad.long });
    new google.maps.Marker(
      {
        position: this.coordinatesB,
        map: this.map,
        icon: this.image
      });
    this.phone1 = ciudad.phone1
    this.phone2 = ciudad.phone2
    this.address = ciudad.address

    event.target.classList.add('citySel');

  }
  ignorar() { }
}
