import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';

import { ServiciosComponent } from './servicios/servicios.component';
import { FuncionesComponent } from './funciones/funciones.component';
import { AboutComponent } from './about/about.component';
import { RecomendacionesSeguridadComponent } from './recomendaciones-seguridad/recomendaciones-seguridad.component'
import{ SitiosInteresComponent } from './sitios-interes/sitios-interes.component'
import { AdministracionRiesgosComponent } from './administracion-riesgos/administracion-riesgos.component'
import {CompaniasIntermediamosComponent} from './companias-intermediamos/companias-intermediamos.component'
import {SucisComponent} from './sucis/sucis.component'
import { AccesoClientesComponent } from './acceso-clientes/acceso-clientes.component'
import { DefensorConsumidorComponent } from './defensor-consumidor/defensor-consumidor.component'
import { EstructuracionMontajeManejoComponent } from './estructuracion-montaje-manejo/estructuracion-montaje-manejo.component'
import { AsesoriaProcesosContratacionComponent } from './asesoria-procesos-contratacion/asesoria-procesos-contratacion.component'
import { AdministracionSegurosComponent } from './administracion-seguros/administracion-seguros.component'
import { SeguridadSaludTrabajoComponent } from './seguridad-salud-trabajo/seguridad-salud-trabajo.component'
import { PrevencionPerdidasComponent } from './prevencion-perdidas/prevencion-perdidas.component'
import { AsesoriaSiniestrosComponent } from './asesoria-siniestros/asesoria-siniestros.component'
import { DatosPersonalesComponent } from './datos-personales/datos-personales.component'
import { ContactComponent } from './contact/contact.component'
import { NotFoundComponent } from './not-found/not-found.component'
import { from } from 'rxjs';
const routes: Routes = [
  {
    path: '',
    redirectTo: "/home",
    pathMatch: 'full'
  },
  { path: 'home', component: LandingPageComponent },
  { path: 'servicios', component: ServiciosComponent },
  { path: 'funciones', component: FuncionesComponent },
  { path: 'nosotros', component: AboutComponent },
  { path: 'seguridad', component: RecomendacionesSeguridadComponent },
  { path: 'interes', component: SitiosInteresComponent },
  { path: 'adminRiesgos', component: AdministracionRiesgosComponent },
  { path: 'intermediamos', component: CompaniasIntermediamosComponent },
  { path: 'sucis', component: SucisComponent },
  { path: 'accesoClientes', component: AccesoClientesComponent },
  { path: 'defensor', component: DefensorConsumidorComponent },
  { path: 'estructuracion', component: EstructuracionMontajeManejoComponent},
  { path: 'asesoriaContratacion', component: AsesoriaProcesosContratacionComponent},
  { path: 'adminSeguros', component: AdministracionSegurosComponent},
  { path: 'seguridadSaludTrabajo', component: SeguridadSaludTrabajoComponent},
  { path: 'perdidas', component: PrevencionPerdidasComponent},
  { path: 'siniestros', component: AsesoriaSiniestrosComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'datosPersonales', component: DatosPersonalesComponent},
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
