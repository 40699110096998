import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prevencion-perdidas',
  templateUrl: './prevencion-perdidas.component.html',
  styleUrls: ['./prevencion-perdidas.component.css']
})
export class PrevencionPerdidasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
