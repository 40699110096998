export class City {

    constructor(
        public lat:number,
        public long:number,
        public address:string,
        public phone1:number,
        public phone2:number,
        public id:string
    ) {  }
  
  }