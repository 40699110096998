export class Contact {

    constructor(
        public name:String,
        public email:String,
        public phone:String,
        public city:String,
        public category:String,
        public service:String,
        public rate: Number,
        public message:String,
        public value: Number,
        
        
    ) {  }
  
  }