import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-administracion-seguros',
  templateUrl: './administracion-seguros.component.html',
  styleUrls: ['./administracion-seguros.component.css']
})
export class AdministracionSegurosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
