import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sucis',
  templateUrl: './sucis.component.html',
  styleUrls: ['./sucis.component.css']
})
export class SucisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
