import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { FooterComponent } from './footer/footer.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { FuncionesComponent } from './funciones/funciones.component';
import { RecomendacionesSeguridadComponent } from './recomendaciones-seguridad/recomendaciones-seguridad.component';
import { AboutComponent } from './about/about.component';
import { SitiosInteresComponent } from './sitios-interes/sitios-interes.component';
import { AdministracionRiesgosComponent } from './administracion-riesgos/administracion-riesgos.component';
import { CompaniasIntermediamosComponent } from './companias-intermediamos/companias-intermediamos.component';
import { SucisComponent } from './sucis/sucis.component';
import { AccesoClientesComponent } from './acceso-clientes/acceso-clientes.component';
import { DefensorConsumidorComponent } from './defensor-consumidor/defensor-consumidor.component';
import { EstructuracionMontajeManejoComponent } from './estructuracion-montaje-manejo/estructuracion-montaje-manejo.component';
import { AsesoriaProcesosContratacionComponent } from './asesoria-procesos-contratacion/asesoria-procesos-contratacion.component';
import { AdministracionSegurosComponent } from './administracion-seguros/administracion-seguros.component';
import { SeguridadSaludTrabajoComponent } from './seguridad-salud-trabajo/seguridad-salud-trabajo.component';
import { PrevencionPerdidasComponent } from './prevencion-perdidas/prevencion-perdidas.component';
import { AsesoriaSiniestrosComponent } from './asesoria-siniestros/asesoria-siniestros.component';
import { ContactComponent } from './contact/contact.component';
import { DatosPersonalesComponent } from './datos-personales/datos-personales.component';
import { FormsModule }   from '@angular/forms';
import {AutosizeModule} from 'ngx-autosize';

import { NotFoundComponent } from './not-found/not-found.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    FooterComponent,
    LandingPageComponent,
    ServiciosComponent,
    FuncionesComponent,
    RecomendacionesSeguridadComponent,
    AboutComponent,
    SitiosInteresComponent,
    AdministracionRiesgosComponent,
    CompaniasIntermediamosComponent,
    SucisComponent,
    AccesoClientesComponent,
    DefensorConsumidorComponent,
    EstructuracionMontajeManejoComponent,
    AsesoriaProcesosContratacionComponent,
    AdministracionSegurosComponent,
    SeguridadSaludTrabajoComponent,
    PrevencionPerdidasComponent,
    AsesoriaSiniestrosComponent,
    ContactComponent,
    DatosPersonalesComponent,
    NotFoundComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AutosizeModule,
    HttpClientModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
