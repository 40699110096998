import { Component, OnInit } from '@angular/core';
import {Contact} from '../clases/Contact'
import { from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import swal from 'sweetalert';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private http: HttpClient) { }

  endpoint = 'https://us-central1-jargusa.cloudfunctions.net/sendMailContact';
  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':  'application/json'
    }),
    observe: 'response' as 'body'
  };
  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  model = new Contact('', '','','', '','', 5, '',0);

  submitted = false;
  onRatingSet($event){
    console.log($event)
    this.model.rate = $event
  }
  servicio(val,num){
    this.model.category = val
    this.model.value = num
  }
  onSubmit() { 
      this.submitted = true;
      this.sendEmail()
    
   }
   sendEmail() {
  
    const data = {
      'user':{
      'nombre':this.model.name,
      'correo': this.model.email,
      'telefono': this.model.phone,
      'ciudad': this.model.city,
      'motivo': this.model.category,
      'servicio': this.model.service,
      'rate':Math.floor(Math.random() * (5 - 1 + 1) + 1),
      'message': this.model.message,
      'value':this.model.value
      }
    }
    this.http.post(this.endpoint, data, this.httpOptions).subscribe();
    swal("Mensaje enviado", "A tu teléfono o correo te estaremos contactando!", "success").then((value) => {
      this.model = new Contact('', '','','', '','', 5, '',0);
    });
    
  }
}
