import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-defensor-consumidor',
  templateUrl: './defensor-consumidor.component.html',
  styleUrls: ['./defensor-consumidor.component.css']
})
export class DefensorConsumidorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
