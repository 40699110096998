import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-companias-intermediamos',
  templateUrl: './companias-intermediamos.component.html',
  styleUrls: ['./companias-intermediamos.component.css']
})
export class CompaniasIntermediamosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
